import React from "react";
import {
  PageHeadingParallax,
  ParagraphA,
  StjDisplayOnScrollContainer,
  StJohnMainContainerSlowParalaxContainer1,
  StJohnMainContainer1,
} from "../../layout/containers/containers.jsx";
import {
  StjMainContainerFontPatuaH2,
  StjMainContainerFontPatuaH4,
  StjMainContainerFontSourceSansProH4,
} from "../../layout/headings/headings.jsx";

export const AboutUsPage = () => (
  <div>
    <PageHeadingParallax
      backgroundImage={"./assets_2/images/iconostasis.jpg"}
      text={"About Us"}
    />
    <StJohnMainContainer1>
      <VisionMissionCommunitySection />
    </StJohnMainContainer1>

    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <StjDisplayOnScrollContainer>
        <figure>
          <img
            src="./assets_2/images/building_front_1.jpg"
            alt="St. John Front of Building"
            className="col-12 offset-lg-2 col-lg-8 stj-shadow-1"
          />
        </figure>
      </StjDisplayOnScrollContainer>
    </StJohnMainContainer1>

    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <AboutUsSummary />
    </StJohnMainContainer1>

    <StJohnMainContainerSlowParalaxContainer1
      backgroundImage={"./assets_2/images/parish_sign.jpeg"}
    />

    <StJohnMainContainer1>
      <Clergy />
    </StJohnMainContainer1>

    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <ParishCouncil />
    </StJohnMainContainer1>
  </div>
);

const VisionMissionCommunitySection = () => (
  <div className="row">
    <section className="col-12 col-lg-4">
      <div className="text-center mt-4 mt-lg-0 mb-2">
        <StjMainContainerFontPatuaH4>Our Vision</StjMainContainerFontPatuaH4>
      </div>
      <p className="fst-italic">
        At St. John the Theologian Orthodox Church, you will feel God's love so
        deeply and hear God's word so clearly, that you will be inspired to
        become the saint God calls you to be.
      </p>
    </section>
    <section className="col-12 col-lg-4">
      <div className="text-center mt-4 mt-lg-0 mb-2">
        <StjMainContainerFontPatuaH4>Our Mission</StjMainContainerFontPatuaH4>
      </div>
      <p className="fst-italic">
        St. John the Theologian Orthodox Church of Shirley, NY is commited to
        reaching out to the people of eastern Suffolk County who are seeking
        God, by providing loving community, meaningful worship, faithful
        teaching and caring spiritual support.
      </p>
    </section>
    <section className="col-12 col-lg-4">
      <div className="text-center mt-4 mt-lg-0 mb-2">
        <StjMainContainerFontPatuaH4>Our Community</StjMainContainerFontPatuaH4>
      </div>
      <p className="fst-italic">
        St. John the Theologian Orthodox Church is a community of compassionate,
        caring people united in worshipping God, in the English language, in the
        historical, unchanging truths of Orthodox Christianity. It is a place to
        receive caring spiritual support on your path towards discovering God's
        will for your life. Here, you celebrate the joys of life, receive
        comfort from life's pain, and receive help with life's needs.
      </p>
      <br />
    </section>
  </div>
);

const AboutUsSummary = () => (
  <>
    <ParagraphA>
      St. John's is presently still the only English-language Orthodox Church
      serving the eastern end of Suffolk County. Our parish is a "pan-Orthodox"
      parish consisting of families from numerous ethnic backgrounds, truly
      making our community "a house of prayer for all people." Parishioners come
      from all over the eastern end of Suffolk County with approximately
      two-thirds to three-fourths of our membership drawn from a ten-mile
      radius.
    </ParagraphA>
    <ParagraphA>
      Our parish has participated in raising money for foreign and domestic
      missions, in clothing and food drives for the needy (both here and
      abroad), and our community has a strong and growing sense of outreach to
      others in the Lord's service. Stewardship, including tithing, is strongly
      emphasized.
    </ParagraphA>
    {/* <ParagraphA>
          An active social schedule, such as our Annual Blini Luncheon on
          Forgiveness Sunday, the Parish Christmas Yolka, Parish Picnic, JOY
          (church school) Pool Party, weekly community meals, trips to museums,
          seminaries, and monasteries, and annual events such as that begun by
          the ladies of the parish Sisterhood combining Christmas shopping and
          womens’ fellowship with a “Sisters Day Out” during the month of
          December, provide the community with joyous opportunities for building
          fellowship and strengthening parish family ties, so sorely needed in
          our times to fight the feelings of alienation, lonliness and isolation
          that so many people experience.
        </ParagraphA> */}
    <ParagraphA>
      Parishioners are encouraged to seek out an area of ministry suitable to
      the gifts God has given them, and use those gifts for His glory and the
      upbuilding of His Church. Such broad participation of the laity ensures
      that a wide range of opinions and ideas will be brought into the process
      of formulating parish vision and mission, prioritizing parish objectives
      and laying the groundwork necessary to make effective, meaningful and
      life-changing ministry happen.
    </ParagraphA>
    <ParagraphA>
      Plans are currently underway to establish a community-based summer
      Vacation Bible School program and Scouting program (there are none in our
      area as of now), as well as chapters for 12-step programs and bereavement
      and other support groups.
    </ParagraphA>
  </>
);

const ClergyPanel = ({ name, imageSrc, children }) => (
  <article className="row mt-5 mb-3">
    <div className="my-2">
      <StjMainContainerFontSourceSansProH4>
        {name}
      </StjMainContainerFontSourceSansProH4>
    </div>
    {imageSrc ? (
      <figure className="col-12 col-lg-4">
        <StjDisplayOnScrollContainer>
          <img src={imageSrc} alt={name} className="w-100" />
        </StjDisplayOnScrollContainer>
      </figure>
    ) : null}
    <section className={imageSrc ? "col-12 col-lg-8" : "col-12"}>
      {children}
    </section>
  </article>
);

const ParishCouncil = () => (
  <>
    <div className="text-center">
      <div id="our-clergy">
        <StjMainContainerFontPatuaH2>
          Parish Council
        </StjMainContainerFontPatuaH2>
      </div>
    </div>
    <section className="mt-4">
      <div className="d-flex justify-content-center flex-wrap text-center">
        <p className="mx-4 my-2">
          <span className="fs-5 font-source-sans-pro fw-bold">Treasurer</span>
          <br />
          JoAnn Broda
        </p>

        <p className="mx-4 my-2">
          <span className="fs-5 font-source-sans-pro fw-bold">Secretatry</span>
          <br />
          Adele McHugh
        </p>

        <p className="mx-4 my-2">
          <span className="fs-5 font-source-sans-pro fw-bold">
            Building & Grounds
          </span>
          <br />
          Jerzy Grygoruk
        </p>

        <p className="mx-4 my-2">
          <span className="fs-5 font-source-sans-pro fw-bold">Sisterhood</span>
          <br />
          Barbara Lade
        </p>

        <p className="mx-4 my-2">
          <span className="fs-5 font-source-sans-pro fw-bold">
            Liturgical Choir
          </span>
          <br />
          Russell Ricciardi
        </p>

        <p className="mx-4 my-2">
          <span className="fs-5 font-source-sans-pro fw-bold">
            40th Anniversary Committee
          </span>
          <br />
          Elaine Del Gigante
        </p>
      </div>
    </section>
  </>
);

const Clergy = () => (
  <>
    <div className="text-center">
      <div id="our-clergy">
        <StjMainContainerFontPatuaH2>Our Clergy</StjMainContainerFontPatuaH2>
      </div>
    </div>
    <ClergyPanel
      name="Archbishop Michael"
      imageSrc="assets_2/clergy/michael_dahulich.jpeg"
    >
      <ParagraphA>
        Archbishop Michael (Dahulich) is the archbishop of the Orthodox Church
        in America Diocese of New York and New Jersey. He is the former dean of
        St. Tikhon's Orthodox Theological Seminary in South Canaan,
        Pennsylvania, and serves there as Rector and Associate Professor of New
        Testament and Ethics.
      </ParagraphA>
      <ParagraphA>
        Archbishop Michael earned a B.Th. in Theology from Christ the Saviour
        Seminary in Johnstown, Pennsylvania; a B.A. in Philosophy from St.
        Vincent College in Latrobe, Pennsylvania; and an M.A. and Ph.D. in
        Theology from Duquesne University in Pittsburgh. He is also studying
        Business at Villanova University.
      </ParagraphA>
      <ParagraphA>
        On September 22, 2009, Archbishop Michael was elected as the bishop of
        OCA's newly reconstituted Diocese of New York and New Jersey. On October
        24 of that year, he was tonsured a rassophore monk by Metr. Jonah at St.
        Tikhon's Orthodox Monastery in preparation for his consecration to the
        episcopacy.
      </ParagraphA>
      <ParagraphA>
        On December 15, 2009, by a decision of the Holy Synod, Archbishop
        Michael was appointed administrator of the Diocese of New York and New
        Jersey, a position he assumed effective January 1, 2010, allowing him to
        begin assuming the everyday running of the diocese, pending his
        consecration, which took place on May 8, 2010, in Ss. Peter and Paul
        Orthodox Church in Jersey City, New Jersey.
      </ParagraphA>
    </ClergyPanel>

    <ClergyPanel
      name="Archpriest Jonathan Ivanoff"
      imageSrc={"assets_2/clergy/fr_jonathan_ivanoff.jpg"}
    >
      <ParagraphA>
        Fr. Jonathan is originally from Los Angeles, CA, and grew up in
        Hollywood's Bulgarian Orthodox Church in a close-knit
        Macedonian-Bulgarian family and community. He is a 1986 graduate of St.
        Vladimir's Orthodox Theological Seminary and was ordained a deacon in
        January of 1992 and a priest in April of 1993, having served at St.
        John's ever since. He is married and has two children.
      </ParagraphA>
    </ClergyPanel>

    <ClergyPanel
      name="Hierodeacon Stephen Guirguis"
      imageSrc={"assets_2/clergy/deacon_stephen.jpg"}
    >
      <ParagraphA>
        Hieromonk Stephen was born in 1978 and grew up in a conservative family
        in upper Egypt. His parents were very religious people. His mother
        particularly played a major role in establishing his faith. After
        finishing high school he left for another city in upper Egypt to attend
        college and study pharmacology. The life-changing event that took place
        in this stage was meeting his spiritual father, Fr. Macarius Meshreky.
      </ParagraphA>
      <ParagraphA>
        After graduating from college in May 2001, he left upper Egypt for its
        capital, Cairo, to join the monastic order there. His monastic order
        required members to work outside the monastery to be able, on the one
        hand, to support themselves financially and, on the other hand, to
        discover themselves from within to go authentically and steadfastly
        through the way of sanctification. The next phase of his life was
        amazing because of the intervention of divine God. It started with a
        tragedy in Egypt with the outbreak of the Arabic Spring in 2011. His
        spiritual father (Fr. Macarius) told his community beforehand that he
        received from God an order to leave the county and to move to the U.S.A.
        Within the next few years, they all came together in Arizona where their
        monastery is currently located. Fr. Macarius opened his community to the
        Eastern Orthodox Church - her ritual and liturgical prayers, her
        theologians both old and recent, and her saints, both their biographies
        and teachings. Eventually the community joined the OCA. The monastery
        also has helped others learn about the Orthodox Church.
      </ParagraphA>
      <ParagraphA>
        Hieromonk Stephen is now in his third year at St. Vladimir's Orthodox
        Theological Seminary. Overall he is very grateful to God for his work in
        his life at various stages and on many levels. He is also thankful for
        all the people God sent in his life and prays that he never loses an
        opportunity to serve Him, His Church, and His people properly.
      </ParagraphA>
    </ClergyPanel>

    <ClergyPanel
      name="Choir Director: Rdr. Robert Sirico"
      imageSrc={"assets_2/clergy/robert_sirico.jpg"}
    >
      <ParagraphA>
        Robert Sirico, (Tonsured 2009, by Met. Jonah) a physics educator for his
        whole secular career, has also been the Choir Director of St. John the
        Theologian Orthodox Church since 2008. The Rdr. Robert holds a
        Bachelor's Degree in Music Composition from Empire State College in
        association with State University of New York, Stonybrook Music
        Department, as well as participating in numerous master classes in
        musicianship.
      </ParagraphA>
      <ParagraphA>
        Among his interests in church singing and conducting, he is the founder
        of the{" "}
        <a
          href="https://www.youtube.com/c/OrthodoxChristianMusicProject/about"
          target="_blank"
        >
          Orthodox Christian Music Project (OCMP33 @ YouTube)
        </a>{" "}
        where he premiers original sacred music for Orthodox liturgical
        services, Oratorios on the Lives of Saints, and numerous settings of
        Orthodox hymnography. Robert’s music has been featured in several
        concerts and hierarchical services in the OCA and Russian Orthodox
        Church, as well as having his concert CD's installed into Ancient Faith
        Radio archives where they are featured regularly. His music has also
        been published in the music journal of the 'International Society for
        Orthodox Christian Music'. Robert was awarded the Grammata by Archbishop
        Michael in 2016 for his service to the parish and for his contributions
        to the corpus of liturgical music in English, and he currently has
        published 5 volumes of original sacred music. Robert's primary musical
        focus at St. John's is developing the liturgical music program to
        provide aesthetically beautiful services to edify the worshiping
        community.
      </ParagraphA>
    </ClergyPanel>

    <ClergyPanel
      name="Reader Russell Ricciardi"
      imageSrc={"assets_2/clergy/russell_2.jpg"}
    >
      <ParagraphA>
        "Received into Orthodox Church in 1988, with my original baptismal name
        John, for St. John the Theologian. Joined St. John the Theologian Church
        in 2010. Came for the committees, stayed for the coffee hours. Started
        singing in the choir and reading the Epistle. Ordained as a Reader by
        Archbishop Michael on October 4, 2020. Lord have mercy."
      </ParagraphA>
    </ClergyPanel>
  </>
);
