import React from "react";
import YearAlbum from "./year-album.jsx";
import { fetchAlbumsDataObjects } from "../../ajax-calls/image-gallery-ajax-calls.js";

class AlbumThumbnailIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AlbumsDataObjects: [],
    };
  }

  componentDidMount() {
    this.handleAlbumsDataFetching();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.year !== prevProps.year) {
      this.handleAlbumsDataFetching();
    }
  }

  handleAlbumsDataFetching() {
    let setState = this.setState.bind(this);

    fetchAlbumsDataObjects(this.props.year)
      .then((albumsDataObjects) => {
        setState({ AlbumsDataObjects: albumsDataObjects });
      })
      .catch((error) => console.log(error.message));
  }

  returnAlbumFigureId(year, albumObject) {
    return "album-" + year.toString() + "-" + albumObject.albumNameOnly;
  }

  displayAlbumsForYear() {
    return this.state.AlbumsDataObjects.map((albumObject, idx) => {
      return (
        <YearAlbum
          idx={idx}
          albumName={albumObject.albumName}
          albumTitle={albumObject.albumTitle}
          albumDate={albumObject.albumDateAsString}
          year={this.props.year}
          key={`${albumObject.albumName}-${albumObject.albumDateAsString}-${this.props.year}-${idx}`}
        />
      );
    });
  }

  render() {
    return (
      <ThumbnailsContainer>{this.displayAlbumsForYear()}</ThumbnailsContainer>
    );
  }
}

export default AlbumThumbnailIndex;

export const ThumbnailsContainer = ({ children }) => (
  <section className="gallery-year-displays">
    <section className="photo-year-container">{children}</section>
  </section>
);
